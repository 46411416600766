import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import { PageLayout } from '../components/layout';
import { AppIcon } from 'common';
import { MainContent } from '../components/layout/main-content';

const Congratulations = () => {
  /**
   * DOM
   */
  return (
    <StaticQuery
      query={graphql`
        query getMdDataCongrats {
          allMarkdownRemark(sort: { fields: [frontmatter___order], order: ASC }) {
            edges {
              node {
                html
                frontmatter {
                  order
                  id
                  title
                  featuredImage {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                    }
                  }
                  slug
                  category
                  type
                  date
                  readingTime
                }
              }
            }
          }
        }
      `}
      render={data => {
        const { allMarkdownRemark } = data || {};
        if (!allMarkdownRemark) {
          return null;
        }
        return (
          <PageLayout bgCls="bg-accent-light">
            <main className="congratulations">
              <MainContent>
                <section className="grid grid-cols-5 lg:grid-cols-14 gap-x-3 px-8 py-16 md:my-0 items-center">
                  <h2 className="col-start-1 col-span-full lg:col-start-4 lg:col-span-8 lg:text-sh-h2 font-semibold text-center mb-8">
                    Confirmation
                    <br />
                    We’ll be in touch soon
                  </h2>
                  <p className="col-start-1 col-span-full lg:col-start-4 lg:col-span-8 justify-self-center text-center typo-b1 ">
                    Thank you for confirming. <br />
                    We’ll be in touch with you soon to let you know when the platform is
                    open, and ready for trading.
                  </p>
                </section>
                <section className="grid grid-cols-5 lg:grid-cols-14 gap-x-3 pb-16 md:my-0 items-center">
                  <div className="col-start-1 col-span-full justify-self-center row-start-1">
                    <AppIcon icon="email-checked" size={60} />
                  </div>
                  <div className="col-start-1 col-span-full lg:col-start-4 lg:col-span-8 row-start-2 justify-items-center ">
                    <p className="text-sh-b1 md:text-sh-h4 text-center my-8">
                      Right now, you could try this… <br />
                      We’re making the world of crypto a smarter, simpler place. And with
                      this in mind, we&apos;ve put together some quick tips on everything
                      from buying, earning and trading digital currencies.
                    </p>
                    <div className="flex flex-col items-center my-20">
                      {/* @ts-ignore  */}
                      <Link
                        to={`/insights`}
                        className={`app-button-primary hover:no-underline`}
                      >
                        Go to Insights
                      </Link>
                    </div>
                  </div>
                </section>
              </MainContent>
            </main>
          </PageLayout>
        );
      }}
    />
  );
};

export default Congratulations;
